import { inject }                                  from 'aurelia-framework';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { InspectingEntitiesRepository }            from 'modules/entities/inspecting-entities/services/repository';
import { InspectingEntity }                        from 'modules/entities/models/inspecting-entity';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(CountiesRepository, InspectingEntitiesRepository, LocationsFormSchema, ParishesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param inspectingEntitiesRepository
     * @param locationsFormSchema
     * @param parishesRepository
     */
    constructor(countiesRepository, inspectingEntitiesRepository, locationsFormSchema, parishesRepository) {
        this.countiesRepository           = countiesRepository;
        this.inspectingEntitiesRepository = inspectingEntitiesRepository;
        this.locationsFormSchema          = locationsFormSchema;
        this.parishesRepository           = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {InspectingEntity}
     */
    model() {
        let model = new InspectingEntity();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  12,
        };

        this.address = {
            type:     'text',
            key:      'address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.fax_number = {
            type:     'text',
            key:      'fax_number',
            label:    'form.field.fax-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'email',
            key:      'email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  4,
        };

        this.certificate_number = {
            type:  'text',
            key:   'certificate_number',
            label: 'form.field.certificate-number',
            size:  4,
        };

        this.certificate_deadline = {
            type:            'material-ui-date-picker',
            key:             'certificate_deadline',
            label:           'form.field.certificate-deadline',
            size:            4,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        return [
            [this.name],
            ...this.locationsFormSchema.schema(viewModel.model, false),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status_id],
            [this.certificate_number, this.certificate_deadline],
        ];
    }

    /**
     * Returns the settings form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    settingsSchema(viewModel) {
        this.expertise_inquiry = {
            type:     'quill',
            key:      'expertise_inquiry',
            label:    'form.field.expertise-inquiry',
            size:     12,
            required: false,
            settings: {
                toolbar: [
                    [{'wildcards': [':date', ':county', ':code', ':address']}],
                ],
                handlers: {
                    'wildcards': (value) => {
                        const editor = viewModel.formSchema.expertise_inquiry.instance.editor;
                        const index  = editor.getSelection().index;

                        editor.insertText(index, value);
                        editor.setSelection(index + value.length);
                    },
                },
            },
        };

        return [
            [this.expertise_inquiry],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            required:     false,
            remoteSource: this.inspectingEntitiesRepository.getUsers.bind(this.inspectingEntitiesRepository, viewModel.model.id),
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToInspectingEntity(false),
            action:     () => viewModel.redirectToRoute('entities.inspecting-entities.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
