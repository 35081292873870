import { Inspector } from 'modules/entities/models/inspector';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Inspector}
     */
    model() {
        let model = new Inspector();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.entity = {
            type:       'text',
            key:        'entity',
            label:      'form.field.inspecting-entity',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.inspector-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       8,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.district = {
            type:       'text',
            key:        'district',
            label:      'form.field.district',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.county = {
            type:       'text',
            key:        'county',
            label:      'form.field.county',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.parish = {
            type:       'text',
            key:        'parish',
            label:      'form.field.parish',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.address = {
            type:       'text',
            key:        'address',
            label:      'form.field.address',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.zip_code = {
            type:       'text',
            key:        'zip_code',
            label:      'form.field.zip-code',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.locality = {
            type:       'text',
            key:        'locality',
            label:      'form.field.locality',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.phone_number = {
            type:       'text',
            key:        'phone_number',
            label:      'form.field.phone-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.mobile_number = {
            type:       'text',
            key:        'mobile_number',
            label:      'form.field.mobile-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.email = {
            type:       'email',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.civil_identification_number = {
            type:       'text',
            key:        'civil_identification_number',
            label:      'form.field.civil-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.tax_identification_number = {
            type:       'text',
            key:        'tax_identification_number',
            label:      'form.field.tax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.entity],
            [this.code, this.name],
            [this.district, this.county, this.parish],
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.email],
            [this.civil_identification_number, this.tax_identification_number, this.status],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('entities.inspectors.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
