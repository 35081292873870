import { inject }                                  from 'aurelia-framework';
import { BooleanOption }                           from 'modules/administration/models/boolean-option';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { InspectingEntitiesRepository }            from 'modules/entities/inspecting-entities/services/repository';
import { Inspector }                               from 'modules/entities/models/inspector';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';
import { UsersRepository }                         from 'modules/administration/users/services/repository';

@inject(CountiesRepository, LocationsFormSchema, InspectingEntitiesRepository, ParishesRepository, UsersRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id:         BooleanStatus.ACTIVE,
        associate_user_id: BooleanOption.NO,
    };

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param locationsFormSchema
     * @param inspectingEntitiesRepository
     * @param parishesRepository
     * @param usersRepository
     */
    constructor(countiesRepository, locationsFormSchema, inspectingEntitiesRepository, parishesRepository, usersRepository) {
        this.countiesRepository           = countiesRepository;
        this.locationsFormSchema          = locationsFormSchema;
        this.inspectingEntitiesRepository = inspectingEntitiesRepository;
        this.parishesRepository           = parishesRepository;
        this.usersRepository              = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Inspector}
     */
    model() {
        let model = new Inspector();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.inspecting-entity',
            size:         4,
            options:      [],
            remoteSource: this.inspectingEntitiesRepository.all.bind(this.inspectingEntitiesRepository),
            attributes:   {
                disabled: viewModel.appContainer.authenticatedUser.belongsToInspectingEntity(false),
            },
        };

        this.associate_user_id = {
            type:      'boolean-options-select',
            key:       'associate_user_id',
            label:     'form.field.associate-existing-user?',
            size:      4,
            hidden:    viewModel.model.user_id !== null,
            required:  false,
            observers: [
                (newValue) => {
                    viewModel.model.user_id = newValue === BooleanOption.YES ? viewModel.model.user_id : null;

                    this.user_id.hidden                   = newValue !== BooleanOption.YES;
                    this.submitAndCreateUserButton.hidden = newValue === BooleanOption.YES;
                    this.name.attributes.readonly         = newValue === BooleanOption.YES;
                    this.email.attributes.readonly        = newValue === BooleanOption.YES;
                },
            ],
        };

        this.user_id = {
            type:      'users-select',
            key:       'user_id',
            label:     'form.field.user',
            size:      4,
            hidden:    true,
            observers: [
                (newValue) => {
                    if (newValue) {
                        this.usersRepository.find(newValue).then(response => {
                            viewModel.model.name  = response.name;
                            viewModel.model.email = response.email;
                        });
                    } else {
                        viewModel.model.name  = viewModel.initialModel.name;
                        viewModel.model.email = viewModel.initialModel.email;
                    }
                },
            ],
        };

        this.code = {
            type:  'text',
            key:   'code',
            label: 'form.field.inspector-number',
            size:  4,
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       8,
            attributes: {
                readonly: false,
            },
        };

        this.address = {
            type:     'text',
            key:      'address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:       'email',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   viewModel.model.user_id !== null,
            attributes: {
                readonly: false,
            },
        };

        this.civil_identification_number = {
            type:     'text',
            key:      'civil_identification_number',
            label:    'form.field.civil-number',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  4,
        };

        return [
            [this.entity_id, this.associate_user_id, this.user_id],
            [this.code, this.name],
            ...this.locationsFormSchema.schema(viewModel.model, false),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.email],
            [this.civil_identification_number, this.tax_identification_number, this.status_id],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('entities.inspectors.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitAndCreateUserButton = {
            type:       'button',
            label:      'form.button.save-and-create-user',
            hidden:     viewModel.model.user_id !== null,
            action:     () => viewModel.submitAndCreateUser(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.submitAndCreateUserButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
