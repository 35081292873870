import { BooleanStatus }   from 'modules/administration/models/boolean-status';
import { EntityEquipment } from 'modules/entities/models/entity-equipment';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {EntityEquipment}
     */
    model() {
        let model = new EntityEquipment();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     * @param creating
     *
     * @returns {*[]}
     */
    schema(viewModel, creating = true) {
        this.code = {
            type:  'text',
            key:   'code',
            id:    'code_' + viewModel.formId,
            label: 'form.field.code',
            size:  2,
        };

        this.name = {
            type:  'text',
            key:   'name',
            id:    'name_' + viewModel.formId,
            label: 'form.field.name',
            size:  6,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            id:    'status_id_' + viewModel.formId,
            label: 'form.field.status',
            size:  4,
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => viewModel.creationSchemaVisible = viewModel.updateSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => creating ? viewModel.submitCreate() : viewModel.submitUpdate(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:       'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions:    [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.code, this.name, this.status_id],
            [this.buttons],
        ];
    }

}
