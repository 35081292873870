import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseViewModel }                   from 'base-view-model';
import { EntityEquipmentsRepository }      from 'modules/entities/inspecting-entities/equipments/services/repository';
import { FormSchema }                      from 'modules/entities/inspecting-entities/equipments/form-schema';

@customElement('entity-equipments')
@inject(AppContainer, FormSchema, EntityEquipmentsRepository)
export class ListEntityEquipments extends BaseViewModel {

    listingId = 'entities-inspecting-entity-equipments-listing';
    formId    = 'entities-inspecting-entity-equipments-form';

    @bindable readonly = false;
    @bindable entity;

    repository;
    datatable;
    creationSchemaVisible = false;
    updateSchemaVisible   = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.inspecting-entities.manage',
            'entities.inspecting-entities.view',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.entities.equipment',
            repository:      {
                search:          (criteria) => this.repository.search(this.entity.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            edit:            {
                action:  (row) => {
                    this.creationSchemaVisible = false;

                    this.initUpdateSchema(row);

                    this.updateSchemaVisible = true;
                },
                visible: () => !this.readonly && this.appContainer.authenticatedUser.can(['entities.inspecting-entities.manage', 'entities.inspecting-entities.edit']),
            },
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:   'form.button.create-new',
                    icon:    'icon-plus3',
                    color:   'success',
                    visible: !this.readonly,
                    action:  () => {
                        this.updateSchemaVisible = false;

                        this.initCreationSchema();

                        this.creationSchemaVisible = true;
                    },
                },
            ],
            selectable:      !this.readonly,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'code',
                    name:  'entity_equipments.code',
                    title: 'form.field.code',
                },
                {
                    data:  'name',
                    name:  'entity_equipments.name',
                    title: 'form.field.name',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Initiates the creation form
     */
    initCreationSchema() {
        this.alert        = null;
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);
    }

    /**
     * Initiates the update form
     *
     * @param row
     */
    initUpdateSchema(row) {
        this.alert        = null;
        this.initialModel = this.model = this.formSchema.model().assign(row);
        this.schema       = this.formSchema.schema(this, false);
    }

    /**
     * Submits the creation form
     */
    submitCreate() {
        this.alert = null;

        return this.repository
            .create(this.entity.id, this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.resetForm();
                }
            });
    }

    /**
     * Submits the update form
     */
    submitUpdate() {
        this.alert = null;

        return this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);

            resolve(true);
            reject(new Error('Error'));
        });
    }

}
