import { bindable, inject }         from 'aurelia-framework';
import { AppContainer }             from 'resources/services/app-container';
import { BaseFormViewModel }        from 'base-form-view-model';
import { FormSchema }               from 'modules/entities/inspectors/view/form-schema';
import { InspectorsRepository }     from 'modules/entities/inspectors/services/repository';
import { InspectorFilesRepository } from 'modules/entities/inspectors/services/files-repository';

@inject(AppContainer, FormSchema, InspectorsRepository, InspectorFilesRepository)
export class ViewInspector extends BaseFormViewModel {

    @bindable headerTitle     = 'form.title.view-record';
    @bindable editRecordRoute = 'entities.inspectors.edit';
    @bindable formId          = 'entities-inspectors-view-form';

    alert  = {};
    model  = {};
    schema = {};

    viewing = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository, filesRepository) {
        super(appContainer);

        this.formSchema      = formSchema;
        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.editRecordRoute = appContainer.authenticatedUser.can(['entities.inspectors.manage', 'entities.inspectors.edit']) ? this.editRecordRoute : null;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.inspectors.manage',
            'entities.inspectors.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
